.container{
    width: 100vw;
    height: 100vh;
    display: flex;
}
.sidemenu{
    width: 25%;
    height: 100vh;
    padding: calc(2.5 * var(--base-y)) calc(2.5 * var(--base-x));
}
.content{
    width: 75%;
    height: 100vh;
    overflow: auto;
    padding: calc(2.5 * var(--base-y)) calc(7 * var(--base-x));
}
.collapse .sidemenu{
    width: 10%;
}
.collapse .content{
    width: 90%;
}
@media only screen and (max-width:850px){
    .container{
        flex-direction: column;
    }
    .sidemenu{
        width: 100%;
    }
    .content{
        width: 0%;
        display: none;
        visibility: hidden;
        padding: calc(0 * var(--base-y)) calc(2 * var(--base-x));
    }
    .collapse .sidemenu{
        width: 10%;
        height: 10vh;
    }
    .collapse .content{
        width: 100%;
        display: block;
        visibility: visible;
    }
}