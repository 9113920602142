.heading{
    font-size: 5rem;
    font-weight: 600;
}
.split-heading{
    font-size: 2.8rem;
    font-weight: 600;
    letter-spacing: .4rem;
}
.heading-small{
    font-size: 3.2rem;
    font-weight: 500;
}
.heading-small-regular{
    font-size: 3.2rem;
    font-weight: 400;
}
.heading-smaller{
    font-size: 2.8rem;
    font-weight: 700;
}