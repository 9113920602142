.input{
    width: 100%;
    height: 100%;
    font-size: 2rem;
    font-weight: 500;
    border-radius: .5rem;
    padding: 0 calc(1.5 * var(--base-x));
}
.error{
    color: red;
}