.container{
    height: 100%;
    width: 100%;
    padding: calc(3.9 * var(--base-y)) 0;
    display: flex;
    flex-direction: column;
}
.heading{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: calc(1 * var(--base-y));
}
.filters{
    display: flex;
    font-size: 2rem;
    font-weight: 300;
}
.filters > div:not(:last-child){
    margin-right: calc(2 * var(--base-x));
}
.filters > div{
    width: calc(15.7 * var(--base-x));
    height: calc(3.9 * var(--base-y));
}
.count{
    font-size: 1.6rem;
    font-weight: 200;
    margin-bottom: calc(2.8 * var(--base-y));
}
.content{
    width: 100%;
}
.table{
    width: 100%;
}
.head-row{
    width: 100%;
    display: flex;
    align-items: center;

    font-size: 2rem;
    font-weight: 300;

    margin-bottom: calc(5 * var(--base-y));
}
.head-row > div:first-child{
    width: 17.5%
}
.head-row > div:nth-child(2){
    width: 17.5%
}
.head-row > div:nth-child(3){
    width: 17.5%
}
.head-row > div:nth-child(4){
    width: 17.5%
}
.head-row > div:nth-child(5){
    width: 3%;
}
.head-row > div:nth-child(6),.head-row > div:nth-child(7),.head-row > div:nth-child(8){
    width: 9%
}

.row{
    width: 100%;
    display: flex;
    align-items: center;

    font-size: 2rem;
    font-weight: 400;

    margin-bottom: calc(5 * var(--base-y));
}
.row > div:first-child{
    width: 17.5%
}
.row > div:nth-child(2){
    width: 17.5%
}
.row > div:nth-child(3){
    width: 17.5%
}
.row > div:nth-child(4){
    width: 17.5%
}
.row > div:nth-child(5){
    width: 3%;
}
.row > div:nth-child(6),.row > div:nth-child(7),.row > div:nth-child(8){
    width: 9%
}
.no-one{
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 2rem;
    font-weight: 500;

    flex-grow: 1;
}
.background{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgba(0,0,0,.4);
    overflow: auto;
}
.card{
    width: calc(41.5 * var(--base-x));
    background-color: white;

    padding: calc(2.7 * var(--base-y)) calc(3.5 * var(--base-y));
    border-radius: 2rem;
}
.cta{
    font-size: 2rem;
    font-weight: 500;
    width: 100%;

    text-align: center;
    display: flex;
}
.cta> div:nth-child(2){
    display: flex;
    align-items: center;
}
.cta>div:first-child{
    width: calc(13.5 * var(--base-x));
    margin-right: calc(2.8 * var(--base-x));
}
.form-element{
    margin-bottom: calc(1.9 * var(--base-y));
}
.input-row{
    height: calc(5 * var(--base-y));
    width: 100%;

    display: flex;
    justify-content: space-between;
}
.form_cta{
    width: 100%;
    height: 100%;

    border: 2px solid black;
    border-radius: 1rem;

    font-size: 2rem;
    font-weight: 500;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0 calc(2 * var(--base-x));
}
.form_cta > div:last-child{
    margin-top: calc(.5 * var(--base-y));
}
.input{
    width: 100%;
    height: 100%;
}
.document{
    width: 100%;
    height: 100%;

    border: 2px solid black;
    border-radius: 1rem;

    font-size: 2rem;
    font-weight: 500;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0 calc(2 * var(--base-x));
}
.document > div:last-child{
    display: flex;
    align-items: center;
    font-size: 2rem;
}
.document > div:last-child > div:last-child{
    height: 2rem;
    width: 2rem;
    border-radius: 50%;

    border: 1px solid black;

    display: flex;
    justify-content: center;
    align-items: center;

    margin-left: 2rem;
}
@media only screen and (max-width:850px){
    .container{
        padding: calc(0 * var(--base-y)) 0;
    }
    .mobile-rows{
        width: 100%;
        height: fit-content;
        background-color: white;
        font-size: 2rem;
        margin-bottom: calc(1 * var(--base-y));
    }
    .mobile-row{
        width: 100%;
        margin-bottom: calc(.5 * var(--base-y));
        display: flex;
        justify-content: space-between;
        padding: calc(2 * var(--base-y)) calc(2 * var(--base-x));
    }
    .mobile-row > div{
        width: 50%;
    }
    .filters{
        margin-bottom: calc(2 * var(--base-y));
    }
    .label{
        font-weight: 700;
    }
}