.container{
    width: 100vw;
    min-height: 100vh;
    background-color: var(--color-base);
    padding: calc(10 * var(--base-y)) 0;

    display: flex;
    justify-content: center;
    align-items: center;
}
.card{
    width: calc(41.5 * var(--base-x));
    background-color: white;

    padding: calc(2.7 * var(--base-y)) calc(3.5 * var(--base-y));
    border-radius: 2rem;
}
.heading{
    margin-bottom: calc(2.3 * var(--base-y));
}
.form-element{
    margin-bottom: calc(1.9 * var(--base-y));
}
.form-element:nth-child(5){
    margin-bottom: calc(3.2 * var(--base-y));
}
.label{
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: calc(1.1 * var(--base-y));
}
.input-row{
    height: calc(5 * var(--base-y));
    width: 100%;

    display: flex;
    justify-content: space-between;
}
.input-row-textarea{
    height: calc(20 * var(--base-y));
    width: 100%;

    display: flex;
    justify-content: space-between;
}
.input{
    width: 100%;
    height: 100%;
}
.input textarea{
    width: 100%;
    height: 100%;
    border: 2px solid black;
}
.input input[type="date"]{
    width: 100%;
    height: 100%;
}
.error{
    color:red;
}
.half-input{
    width: 49%;
}
.input:not(:last-child){
    margin-right: calc(.4 * var(--base-x));
}
.realtor-check{
    display: flex;
    /* align-items: center; */
    margin-bottom: calc(1.3 * var(--base-y));
}
.realtor-check > div:first-child{
    margin-right: calc(1.5 * var(--base-x));
    margin-top: calc(.2 * var(--base-y));
}
.realtor-text{
    font-size: 2rem;
    font-weight: 500;
}
.cta{
    font-size: 2rem;
    font-weight: 500;
    width: 100%;

    text-align: center;
    display: flex;
}
.cta> div:nth-child(2){
    display: flex;
    align-items: center;
}
.cta>div:first-child{
    width: calc(13.5 * var(--base-x));
    margin-right: calc(2.8 * var(--base-x));
}
.form_cta{
    width: 100%;
    height: 100%;

    border: 2px solid black;
    border-radius: 1rem;

    font-size: 2rem;
    font-weight: 500;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0 calc(2 * var(--base-x));
}
.form_cta > div:last-child{
    margin-top: calc(.5 * var(--base-y));
}
.image_container{
    position: relative;
}
.image-remove{
    position: absolute;
    z-index: 9;
    top: calc(1 * var(--base-y));
    left: calc(1 * var(--base-x));

    font-size: 2rem;
    height: calc(3 * var(--base-y));
    width: calc(3 * var(--base-x));
    background-color: var(--color-base);
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 2px 2px 5px black;
}
.image{
    width: 100%;
    margin-bottom: calc(1 * var(--base-x));
}
.input img{
    height: calc(3 * var(--base-y));
}
.ammenity_label{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.ammenity_label > div:last-child{
    height: calc(2 * var(--base-y));
    width: calc(2 * var(--base-x));
    
    font-size: 2rem;

    border: 1px solid black;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;
}
.box{
    border: 1px solid black;
    padding: calc(1 * var(--base-x));
    margin-bottom: calc(1 * var(--base-y));
}
.document{
    width: 100%;
    height: 100%;

    border: 2px solid black;
    border-radius: 1rem;

    font-size: 2rem;
    font-weight: 500;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0 calc(2 * var(--base-x));
}
.document > div:last-child{
    display: flex;
    align-items: center;
    font-size: 2rem;
}
.document > div:last-child > div:last-child{
    height: 2rem;
    width: 2rem;
    border-radius: 50%;

    border: 1px solid black;

    display: flex;
    justify-content: center;
    align-items: center;

    margin-left: 2rem;
}
.hr{
    width: 100%;
    height: 2px;
    background-color: black;
    margin-bottom: calc(2 * var(--base-y));
}
.form-element > div:nth-child(3){
    margin-top: calc(2 * var(--base-y));
}
.input-row  textarea{
    height: calc(10 * var(--base-y));
    width: 100%;
}