
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

:root{
  --base-x:8px;
  --base-y:8px;
  --color-base:#FFBB9A;
  --primary-text-color:#000;
  --background-color:white;
}

*,*::after,*::before{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html{
  font-size: 8px;
  color: var(--primary-text-color);
  font-family: "Inter", sans-serif;
  background-color: #f5f5f5;
}

@media only screen and (min-width:1450px){
  :root{
    --base-x:10px;
    --base-y:10px;
  }

  html{
    font-size: 10px;
  }
}

@media only screen and (max-width:1150px){
  :root{
    --base-x:6px;
    --base-y:6px;
  }

  html{
    font-size: 6px;
  }
}

@media only screen and (max-width:850px){
  :root{
    --base-x:8px;
    --base-y:8px;
  }

  html{
    font-size: 8px;
  }
}

@media only screen and (max-width:650px){
  :root{
    --base-x:6px;
    --base-y:6px;
  }

  html{
    font-size: 6px;
  }
}