.container{
    display: flex;
    width: 100vw;
    height: 100vh;
}
.left{
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: calc(7 * var(--base-y));
}
.left > div{
    width: calc(37 * var(--base-x));
    height: 100%;
}
.left > div > div:first-child{
    height: 30%;
}
.content{
    height: 70%;
}
.logo,.logo img{
    width: calc(9 * var(--base-x));
    height: calc(9 * var(--base-y));
    position: relative;
    /* margin-bottom: calc(14 * var(--base-y)); */
    /* margin-bottom: 17.5vh; */
}
.heading{
    margin-bottom: calc(1.3 * var(--base-y));
}
.tagline{
    font-size: 1.6rem;
    font-weight: 300;
    margin-bottom: calc(2.6 * var(--base-y));
}
.tagline a{
    color: var(--color-base);
    font-weight: 700;
    text-decoration: none;
}
.line{
    width: 100%;
    display: flex;
    align-items: center;
}
.line>div:nth-child(2){
    padding: 0 calc(1 * var(--base-x));
    font-size: 2rem;
    font-weight: 500;
}
.hr{
    height: 1px;
    width: 100%;
    background-color: black;
}
.right{
    position: relative;
    width: 50%;
    height: 100%;
}
.right img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.form-element{
    margin-bottom: calc(3.2 * var(--base-y));
    width: calc(33.4 * var(--base-x));
}
.form-element:nth-child(5){
    margin-bottom: calc(3.2 * var(--base-y));
}
.label{
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: calc(1.1 * var(--base-y));
}
.input-row{
    height: calc(5 * var(--base-y));
    width: 100%;

    display: flex;
}
.input{
    width: 100%;
}
.input:not(:last-child){
    margin-right: calc(.4 * var(--base-x));
}
.realtor-check{
    display: flex;
    /* align-items: center; */
    margin-bottom: calc(1.3 * var(--base-y));
}
.realtor-check > div:first-child{
    margin-right: calc(1.5 * var(--base-x));
    margin-top: calc(.2 * var(--base-y));
}
.realtor-text{
    font-size: 2rem;
    font-weight: 500;
}
.disclaimer{
    font-size: 1.2rem;
    font-weight: 300;
    margin-bottom: calc(2.3 * var(--base-y));
}
.disclaimer a{
    color: var(--color-base);
    font-weight: 700;
}
.cta{
    font-size: 2rem;
    font-weight: 500;
    width: 100%;

    text-align: center;
    display: flex;
}
.cta> div:nth-child(2){
    display: flex;
    align-items: center;
}
.cta>div:first-child{
    width: calc(13.5 * var(--base-x));
    margin-right: calc(2.8 * var(--base-x));
}
@media only screen and (max-width:1150px){
    .left h1 {
        font-size: calc(1.4 * 3.2rem) !important;
    }
    .left{
        padding-top: calc(1.4 * 7 * var(--base-y));
    }
    .left > div{
        width: calc(1.4 * 37 * var(--base-x));
    }
    .left input{
        font-size: calc(1.4 * 2rem) !important;
    }
    .logo,.logo img{
        width: calc(1.4 * 9 * var(--base-x));
        height: calc(1.4 * 9 * var(--base-y));
    }
    .heading{
        margin-bottom: calc(1.4 * 1.3 * var(--base-y));
    }
    .tagline{
        font-size: calc(1.4 * 1.6rem);
        margin-bottom: calc(1.4 * 2.6 * var(--base-y));
    }
    .line>div:nth-child(2){
        padding: 0 calc(1.4 * 1 * var(--base-x));
        font-size: calc(1.4 * 2rem);
    }
    .form-element{
        margin-bottom: calc(1.4 * 3.2 * var(--base-y));
        width: calc(1.4 * 33.4 * var(--base-x));
    }
    .form-element:nth-child(5){
        margin-bottom: calc(1.4 * 3.2 * var(--base-y));
    }
    .label{
        font-size: calc(1.4 * 2rem);
        margin-bottom: calc(1.4 * 1.1 * var(--base-y));
    }
    .input-row{
        height: calc(1.4 * 5 * var(--base-y));
    }
    .input:not(:last-child){
        margin-right: calc(1.4 * .4 * var(--base-x));
    }
    .realtor-check{
        margin-bottom: calc(1.4 * 1.3 * var(--base-y));
    }
    .realtor-check > div:first-child{
        margin-right: calc(1.4 * 1.5 * var(--base-x));
        margin-top: calc(1.4 * .2 * var(--base-y));
    }
    .realtor-text{
        font-size: calc(1.4 * 2rem);
    }
    .disclaimer{
        font-size: calc(1.4 * 1.2rem);
        margin-bottom: calc(1.4 * 2.3 * var(--base-y));
    }
    .cta{
        font-size: calc(1.4 * 2rem);
    }
    .cta>div:first-child{
        width: calc(1.4 * 13.5 * var(--base-x));
        margin-right: calc(1.4 * 2.8 * var(--base-x));
    }
}
@media only screen and (max-width:850px){
    .left h1 {
        font-size: calc(1 * 3.2rem) !important;
    }
    .left{
        padding-top: calc(1 * 7 * var(--base-y));
        width: 100%;
    }
    .right{
        display: none;
        visibility: hidden;
    }
    .left > div{
        width: calc(1 * 37 * var(--base-x));
    }
    .left input{
        font-size: calc(1 * 2rem) !important;
    }
    .logo,.logo img{
        width: calc(1 * 9 * var(--base-x));
        height: calc(1 * 9 * var(--base-y));
    }
    .heading{
        margin-bottom: calc(1 * 1.3 * var(--base-y));
    }
    .tagline{
        font-size: calc(1 * 1.6rem);
        margin-bottom: calc(1 * 2.6 * var(--base-y));
    }
    .line>div:nth-child(2){
        padding: 0 calc(1 * 1 * var(--base-x));
        font-size: calc(1 * 2rem);
    }
    .form-element{
        margin-bottom: calc(1 * 3.2 * var(--base-y));
        width: calc(1 * 33.4 * var(--base-x));
    }
    .form-element:nth-child(5){
        margin-bottom: calc(1 * 3.2 * var(--base-y));
    }
    .label{
        font-size: calc(1 * 2rem);
        margin-bottom: calc(1 * 1.1 * var(--base-y));
    }
    .input-row{
        height: calc(1 * 5 * var(--base-y));
    }
    .input:not(:last-child){
        margin-right: calc(1 * .4 * var(--base-x));
    }
    .realtor-check{
        margin-bottom: calc(1 * 1.3 * var(--base-y));
    }
    .realtor-check > div:first-child{
        margin-right: calc(1 * 1.5 * var(--base-x));
        margin-top: calc(1 * .2 * var(--base-y));
    }
    .realtor-text{
        font-size: calc(1 * 2rem);
    }
    .disclaimer{
        font-size: calc(1 * 1.2rem);
        margin-bottom: calc(1 * 2.3 * var(--base-y));
    }
    .cta{
        font-size: calc(1 * 2rem);
    }
    .cta>div:first-child{
        width: calc(1 * 13.5 * var(--base-x));
        margin-right: calc(1 * 2.8 * var(--base-x));
    }
}
@media only screen and (max-width:650px){
    .left h1 {
        font-size: calc(1.2 * 3.2rem) !important;
    }
    .left{
        padding-top: calc(1.2 * 7 * var(--base-y));
    }
    .left > div{
        width: calc(1.2 * 37 * var(--base-x));
    }
    .left input{
        font-size: calc(1.2 * 2rem) !important;
    }
    .logo,.logo img{
        width: calc(1.2 * 9 * var(--base-x));
        height: calc(1.2 * 9 * var(--base-y));
    }
    .heading{
        margin-bottom: calc(1.2 * 1.3 * var(--base-y));
    }
    .tagline{
        font-size: calc(1.2 * 1.6rem);
        margin-bottom: calc(1.2 * 2.6 * var(--base-y));
    }
    .line>div:nth-child(2){
        padding: 0 calc(1.2 * 1 * var(--base-x));
        font-size: calc(1.2 * 2rem);
    }
    .form-element{
        margin-bottom: calc(1.2 * 3.2 * var(--base-y));
        width: calc(1.2 * 33.4 * var(--base-x));
    }
    .form-element:nth-child(5){
        margin-bottom: calc(1.2 * 3.2 * var(--base-y));
    }
    .label{
        font-size: calc(1.2 * 2rem);
        margin-bottom: calc(1.2 * 1.1 * var(--base-y));
    }
    .input-row{
        height: calc(1.2 * 5 * var(--base-y));
    }
    .input:not(:last-child){
        margin-right: calc(1.2 * .4 * var(--base-x));
    }
    .realtor-check{
        margin-bottom: calc(1.2 * 1.3 * var(--base-y));
    }
    .realtor-check > div:first-child{
        margin-right: calc(1.2 * 1.5 * var(--base-x));
        margin-top: calc(1.2 * .2 * var(--base-y));
    }
    .realtor-text{
        font-size: calc(1.2 * 2rem);
    }
    .disclaimer{
        font-size: calc(1.2 * 1.2rem);
        margin-bottom: calc(1.2 * 2.3 * var(--base-y));
    }
    .cta{
        font-size: calc(1.2 * 2rem);
    }
    .cta>div:first-child{
        width: calc(1.2 * 13.5 * var(--base-x));
        margin-right: calc(1.2 * 2.8 * var(--base-x));
    }
}