.container{
    height: 100%;
    width: 100%;
    padding: calc(3.9 * var(--base-y)) 0;
    display: flex;
    flex-direction: column;
}
.heading{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: calc(1 * var(--base-y));
}
.filters{
    display: flex;
    font-size: 2rem;
    font-weight: 300;
}
.filters > div:not(:last-child){
    margin-right: calc(2 * var(--base-x));
}
.filters > div{
    width: calc(15.7 * var(--base-x));
    height: calc(3.9 * var(--base-y));
}
.count{
    font-size: 1.6rem;
    font-weight: 200;
    margin-bottom: calc(2.8 * var(--base-y));
}
.content{
    width: 100%;
}
.table{
    width: 100%;
}
.head-row{
    width: 100%;
    display: flex;
    align-items: center;

    font-size: 2rem;
    font-weight: 300;

    margin-bottom: calc(5 * var(--base-y));
}
.head-row > div:first-child{
    width: 17.5%
}
.head-row > div:nth-child(2){
    width: 17.5%
}
.head-row > div:nth-child(3){
    width: 25%
}
.head-row > div:nth-child(4){
    width: 10%
}
.head-row > div:nth-child(5),.head-row > div:nth-child(6),.head-row > div:nth-child(7){
    width: 10%
}

.row{
    width: 100%;
    display: flex;
    align-items: center;

    font-size: 2rem;
    font-weight: 400;

    margin-bottom: calc(5 * var(--base-y));
}
.row > div:first-child{
    width: 17.5%
}
.row > div:nth-child(2){
    width: 17.5%
}
.row > div:nth-child(3){
    width: 25%
}
.row > div:nth-child(4){
    width: 10%
}
.row > div:nth-child(5),.row > div:nth-child(6),.row > div:nth-child(7){
    width: 10%
}
.no-one{
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 2rem;
    font-weight: 500;

    flex-grow: 1;
}
@media only screen and (max-width:850px){
    .container{
        padding: calc(0 * var(--base-y)) 0;
    }
    .mobile-rows{
        width: 100%;
        height: fit-content;
        background-color: white;
        font-size: 2rem;
        margin-bottom: calc(1 * var(--base-y));
    }
    .mobile-row{
        width: 100%;
        margin-bottom: calc(.5 * var(--base-y));
        display: flex;
        justify-content: space-between;
        padding: calc(2 * var(--base-y)) calc(2 * var(--base-x));
    }
    .mobile-row > div{
        width: 50%;
    }
    .filters{
        margin-bottom: calc(2 * var(--base-y));
    }
    .label{
        font-weight: 700;
    }
}