.container{
    display: flex;
    flex-direction: column;
    font-size: 2rem;
    font-weight: 300;
    width: fit-content;
    cursor: pointer;
}
.line{
    height: calc(.2 * var(--base-y));
    width: 80%;
    background-color: var(--color-base);
    margin-top: calc(.25 * var(--base-y));
    margin: 0 auto;
}
.disable{
    opacity: .7;
    cursor: not-allowed;
}