.container{
    width: 100vw;
    height: 100vh;
    background-color: var(--color-base);

    display: flex;
    justify-content: center;
    align-items: center;
}
.card{
    width: calc(41.5 * var(--base-x));
    background-color: white;

    padding: calc(2.7 * var(--base-y)) calc(3.5 * var(--base-y));
    border-radius: 2rem;
}
.heading{
    margin-bottom: calc(2.3 * var(--base-y));
}
.form-element{
    margin-bottom: calc(1.9 * var(--base-y));
}
.form-element:nth-child(5){
    margin-bottom: calc(3.2 * var(--base-y));
}
.label{
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: calc(1.1 * var(--base-y));
}
.input-row{
    height: calc(5 * var(--base-y));
    width: 100%;

    display: flex;
    justify-content: space-between;
}
.input{
    width: 100%;
}
.half-input{
    width: 49%;
}
.input:not(:last-child){
    margin-right: calc(.4 * var(--base-x));
}
.realtor-check{
    display: flex;
    /* align-items: center; */
    margin-bottom: calc(1.3 * var(--base-y));
}
.realtor-check > div:first-child{
    margin-right: calc(1.5 * var(--base-x));
    margin-top: calc(.2 * var(--base-y));
}
.realtor-text{
    font-size: 2rem;
    font-weight: 500;
}
.cta{
    font-size: 2rem;
    font-weight: 500;
    width: 100%;

    text-align: center;
    display: flex;
}
.cta> div:nth-child(2){
    display: flex;
    align-items: center;
}
.cta>div:first-child{
    width: calc(13.5 * var(--base-x));
    margin-right: calc(2.8 * var(--base-x));
}