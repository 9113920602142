.button{
    padding: calc(1 * var(--base-y)) calc(1.3 * var(--base-x));
    background-color: var(--color-base);
    color: var(--primary-text-color);
    font-weight: 500;
    border-radius: 1rem;
    cursor: pointer;
}
.disable{
    cursor: not-allowed;
    opacity: .5;
}