.container{
    width: 100%;
    height: calc(3.9 * var(--base-y));
    position: relative;
    font-size: 2rem;
    font-weight: 300;
}
.selected{
    width: 100%;
    height: calc(3.9 * var(--base-y));
    display: flex;
    background-color: white;
    align-items: center;
    padding: calc(.4 * var(--base-y)) calc(1 * var(--base-x));
    border: 1px solid #d9d9d9;
}
.selected > div:first-child{
    width: 85%;
    display: flex;
}
.selected > div:last-child{
    width: 15%;
}
.down{
    width: calc(1.5 * var(--base-x));
    height: calc(3.3 * var(--base-y));
    position: relative;
    cursor: pointer;
    color:#d9d9d9;
}
.dropdown{
    top: 110%;
    position: absolute;
    width: 100%;

    background-color: #fff;
    border: 1px solid #d9d9d9;

    transition: all .5s;
    overflow: hidden;

    z-index: 9;
}
.collapse{
    height: 0;
    display: none;
}
.rotate{
    transform: rotateZ(180%);
}
.dropdown-item{
    width: 100%;
    height: calc(3.9 * var(--base-y));
    display: flex;
    justify-content: center;
    align-items: center;
    padding: calc(.4 * var(--base-y)) calc(1 * var(--base-x));
}

.dropdown-item:hover{
    background-color: var(--color-base);
    cursor: pointer;
}