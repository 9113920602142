.container{
    width: 100%;
    height: 100%;

    display: flex;
    background-color: #272130;

    padding: calc(2.3 * var(--base-y)) calc(2.7 * var(--base-x));
    border-radius: 2rem;
}
.content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 100%;
    height: 100%;
}
.collapse .top{
    flex-direction: column-reverse;
    height: 100%;
    padding-bottom: 0;
    padding: calc(5 * var(--base-x)) 0;
}
.collapse .top>div:first-child{
    margin-bottom: calc(2 * var(--base-y));
}
.collapse .middle,.collapse .bottom{
    display: none;
    visibility: hidden;
}
.top{
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding-bottom: calc(8.5 * var(--base-y));
}
.top img{
    width: calc(6 * var(--base-x));
    height: calc(6 * var(--base-y));
}
.hamburger{
    width: calc(2.5 * var(--base-x));
    height: 2px;
    background-color: white;
    position: relative;
}
.hamburger::before{
    content: "";
    position: absolute;
    top: calc(-1 * var(--base-y));
    width: calc(2.5 * var(--base-x));
    height: 2px;
    background-color: white;
}
.hamburger::after{
    content: "";
    position: absolute;
    top: calc(1 * var(--base-y));
    width: calc(2.5 * var(--base-x));
    height: 2px;
    background-color: white;
}

.middle{
    display: flex;
    flex-direction: column;
    color: white;
    font-size: 2rem;
    flex-grow: 1;
}
.nav a{
    color: inherit;
    text-decoration: none;
}
.nav-item{
    display: flex;
    align-items: center;
    padding: calc(1.25 * var(--base-x)) calc(1.7 * var(--base-x));
    border-radius: 5rem;
}
.nav-item{
    margin-bottom: calc(1 * var(--base-y));
}
.active{
    background-color: var(--color-base);
    color: #272130;
}
.nav-item > div:first-child{
    font-size: 2.5rem;
    margin-right: calc(1.8 * var(--base-x));
}
.bottom{

}
.cta{
    width: 100%;
    background-color: var(--color-base);
    color: #272130;
    font-size: 2rem;
    padding: calc(1.25 * var(--base-x)) calc(1.7 * var(--base-x));
    border-radius: 1rem;
    text-align: center;
    cursor: pointer;
}
@media only screen and (max-width:850px){
    .collapse{
        padding: calc(0 * var(--base-y)) calc(1 * var(--base-x));
        height: fit-content;
        width: fit-content;
    }
    .collapse .top{
        padding: calc(3 * var(--base-y)) 0;
    }
    .collapse .top>div:first-child{
       display: none;
       visibility: hidden;
    }
    .collapse .top>div:last-child{
        display: flex;
        justify-content: center;
        align-items: center;
     }
}