.container{
    height: calc(5.3 * var(--base-y));
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.otp{
    width: 15%;
    height: 100%;
}
.otp > input{
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 2rem;
}